var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"max-width":"1920px","margin":"auto","position":"relative"}},[_c('v-row',{staticClass:"mt-1 mb-2 d-flex align-start justify-space-between"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"titlePages text-left font-weight-medium mb-4"},[_vm._v("Configurações ")]),_c('p',{staticClass:"mb-0 text-body-2 font-weight-light text-left mt-2",staticStyle:{"max-width":"50%"}},[_vm._v(" Configure e edite suas operadoras de cartões de crédito para gerar suas transações de cartões de crédito no sistema. ")])])],1),_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v(" Operadoras ")]),_c('v-card-subtitle',{staticClass:"text-left"},[_vm._v(" Lista de operadoras de cartões cadastradas ")]),_c('v-divider',{staticClass:"deep-purple darken-1",staticStyle:{"border":"solid 2px"}}),_c('div',{staticStyle:{"position":"relative"}},[_c('v-fab-transition',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"deep-purple darken-1","fab":"","dark":"","small":"","absolute":"","top":"","right":""},on:{"click":_vm.toogleRegisterOperatorDilog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Cadastrar nova operadora")])])],1)],1),_c('v-card-text',{staticStyle:{"min-height":"450px"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.operators,"items-per-page":5,"no-data-text":"Nenhuma operadora configurada"},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.buildDate(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primaryNew","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":"","rounded":""}},[_c('v-subheader',{staticClass:"text-subtitle-2"},[_c('b',{staticClass:"primaryNew--text"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('v-divider',{staticClass:"mb-2"}),_c('v-list-item-group',{attrs:{"color":"primaryNew"}},[_c('v-list-item',{on:{"click":function($event){return _vm.editConfigOperator(item)}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("drive_file_rename_outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Editar operadora")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.confirmDelConfigOperator(item)}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("delete_forever")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Excluir operadora")])],1)],1)],1)],1)],1)]}}])}),_c('RegisterOperator',{attrs:{"adm":false,"configuredOperators":_vm.configuredOperators,"editConfigData":_vm.editConfigData,"registerOperatorDilog":_vm.registerOperatorDilog,"toogleRegisterOperatorDilog":_vm.toogleRegisterOperatorDilog,"getOperators":_vm.getOperators}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }