<template>
    <v-dialog persistent v-model="registerOperatorDilog" scrollable max-width="750px">
        <v-card>
            <v-card-title style="border-bottom: 1px solid #ccc;" class="px-2 mb-5"><v-app-bar height="50" flat color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="text-h5 pl-0 pt-0">
                        {{ formData.id ? "Editar operadora" : "Nova operadora" }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="toogleRegisterOperatorDilog" class="mt-6" color="red" fab icon dark x-small absolute top
                        right>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar></v-card-title>

            <v-card-text class="pt-0">
                <v-form ref="operatorForm" style="width: 100%">
                    <v-row class="px-3 mt-4">
                        <v-col class="pl-5 pt-0" cols="5">
                            <v-combobox class="requiredField" :rules="$store.state.formRules.required" clearable
                                v-model="formData.name" :items="configuredOperators" label="Selecione uma operadora"
                                item-text="name" item-value="name"></v-combobox>
                        </v-col>
                        <v-col class="pl-5 pt-0" cols="7">
                            <v-text-field clearable class="requiredField" :rules="$store.state.formRules.required"
                                v-model="formData.credentials.merchant_id" label="Merchant id"></v-text-field>
                        </v-col>
                        <v-col class="pl-5 pt-0" cols="12">
                            <v-text-field clearable class="requiredField" :rules="$store.state.formRules.required"
                                v-model="formData.credentials.merchant_key" label="Merchant key"></v-text-field>
                        </v-col>
                        <v-col class="pl-5 pt-0" cols="12">
                            <v-select class="requiredField" :rules="$store.state.formRules.required"
                                label="Tipo de numeração" v-model="formData.credentials.algoritmoNumeracao"
                                :items="algoritimo" item-text="label" item-value="val"></v-select>
                        </v-col>
                        <v-col class="pl-5 pt-0" cols="12">
                            <v-text-field clearable class="" v-model="formData.callback_url"
                                label="Url de callback"></v-text-field>
                        </v-col>

                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="toogleRegisterOperatorDilog">
                    Fechar
                </v-btn>
                <v-btn color="green" text @click="saveConfigOperator"> Salvar </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
const restOperatorForm = {
    name: "",
    callback_url: null,
    credentials: {
        algoritmoNumeracao: "",
        merchant_id: "",
        merchant_key: ""
    }

}
export default {
    watch: {
        registerOperatorDilog(v) {
            // console.log('watch', v)
            if (v) {
                if (this.editConfigData) {
                    this.formData = { ...this.editConfigData }
                }
            } else {
                this.$refs.operatorForm.resetValidation();
                this.formData = { ...restOperatorForm };
            }
        },
    },
    methods: {
        saveConfigOperator() {
            if (!this.$refs.operatorForm.validate()) {
                return;
            }
            if (!this.formData.id) {
                this.formData.name = this.formData.name.name
            }
            var obj = {
                url: this.formData.id ? "/api/v1/paymentCredentials/update" : "/api/v1/paymentCredentials/create",
                query: this.formData,
                method: "post",
            };
            console.log('save', this.formData.name)
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.toogleRegisterOperatorDilog()
                this.getOperators()
                let opts = {
                    message: `Operadora ${this.formData.id ? "atualizada" : "criada"
                        } com sucesso`,
                    type: "success",
                };
                this.$store.commit("showAlert", opts);
            });
        }
    },
    data() {
        return {
            algoritimo: [
                { val: "A", label: "Tipo (A) - Poderá gerar até 9.999 transacões por dia" },
                { val: "B", label: "Tipo (B) - Poderá gerar até 999.999 transações por mês" },
                { val: "C", label: "Tipo (C) - Poderá gerar até 99.999.999 transações por ano" },
                { val: "D", label: "Tipo (D) - Poderá gerar transação com uuid (indicador único universal)" }
            ],
            formData: { ...restOperatorForm },
        }
    },
    props: {
        configuredOperators: Array,
        toogleRegisterOperatorDilog: Function,
        registerOperatorDilog: Boolean,
        getOperators: Function,
        editConfigData: Object
    },
}
</script>

<style lang="scss" scoped></style>